.ceqsj8b{border:none;border-radius:50%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;z-index:2;position:relative;}@media only screen and (max-width:699px){.ceqsj8b{width:2rem;height:2rem;margin-left:0.5rem;}}
.i1ttqdm4{position:relative;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;height:100%;width:40px;height:40px;border-radius:8px;background:var(--i1ttqdm4-0);margin-right:14px;cursor:pointer;}.i1ttqdm4 img{width:24px;height:24px;}.i1ttqdm4:hover{background:#333333;}.i1ttqdm4:hover .notification-indicator{background:#333333;}
.hn8e3h3{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:0 4px 24px 4px;}.hn8e3h3 h1{color:#ffffff;font-size:18px;font-weight:600;line-height:24px;text-transform:initial;}.hn8e3h3 button > span{color:#8e8e93;font-weight:600;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}
.c4y759d{position:absolute;z-index:10;top:55px;-webkit-flex-flow:column;-ms-flex-flow:column;flex-flow:column;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;background-color:#1a1a1a;border-radius:24px;right:24px;border:1px solid #2E2E2E;width:100vw;max-width:400px;box-shadow:0px -4px 16px 0px rgba(0,0,0,0.12);padding:24px 20px 40px 20px;}
.c1ockr9a{width:100%;margin-bottom:24px;min-height:209px;}.c1ockr9a img{width:100%;}
.f1goxua7{margin-bottom:10px;}.f1goxua7 > div{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;margin-bottom:8px;}.f1goxua7 > div h2{color:#ffffff;text-align:center;font-family:"Neusa Next Pro";font-size:52px;font-style:normal;font-weight:700;line-height:52px;text-transform:uppercase;}.f1goxua7 > div span{color:#8e8e93;text-align:center;font-size:36px;font-style:normal;font-weight:600;line-height:44px;margin:0 12px 0 10px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:end;-webkit-box-align:end;-ms-flex-align:end;align-items:end;margin-bottom:2.5px;}.f1goxua7 h3{color:#ffffff;text-align:center;font-size:18px;font-weight:600;line-height:24px;text-transform:initial;}
.d13jkg6e{color:#8e8e93;text-align:center;font-size:18px;font-weight:500;line-height:24px;margin:0 auto;margin-bottom:32px;}
.ckuegas{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;width:32px;height:32px;background:#333333;-webkit-backdrop-filter:blur(4px);backdrop-filter:blur(4px);border-radius:100px;cursor:pointer;border:none;}
.l135tg6w.n1fqbvli{background-color:#048848;bottom:40px;position:fixed;left:16px;right:16px;margin-bottom:0;padding:16px 20px 16px 16px;z-index:20;}.l135tg6w.n1fqbvli span{color:#FFFFFF;font-weight:500;font-size:16px;line-height:24px;}.l135tg6w.n1fqbvli > div span{font-weight:500;}@media only screen and (min-width:769px){.l135tg6w.n1fqbvli{max-width:720px;margin:auto;right:0;}}
.c1x4bcdr{padding:0 12px;}.c1x4bcdr button{width:100%;background:#ffffff;border-radius:60px;padding:16px 32px;}.c1x4bcdr button:hover:active{background-color:#ffffff;}.c1x4bcdr button > div > span{color:#000;font-size:18px;font-weight:600;line-height:24px;}
.nq3gk8s{background:#000000;padding:2px;top:7px;position:absolute;border-radius:100px;width:10px;margin-left:var(--nq3gk8s-0);}.nq3gk8s > div{width:6px;height:6px;background:#e11900;border-radius:100px;}

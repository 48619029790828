.cr9b4o6{width:100%;}
.c18ipk2f{position:relative;grid-row-start:0;grid-row-end:1;background:#f6f6f6;border-radius:8px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;width:100%;padding:16px 32px;margin-bottom:40px;cursor:pointer;}
.ir44wmo{max-width:310px;}.ir44wmo h1{font-weight:600;font-size:28px;line-height:36px;color:#000000;margin-bottom:4px;text-transform:capitalize;}.ir44wmo p{font-weight:500;font-size:16px;line-height:24px;color:#8E8E93;}
.p1sekd4g{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.mwjays1 .modal-journey{height:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;background:#000000;background-repeat:no-repeat;-webkit-background-size:cover;-moz-background-size:cover;-o-background-size:cover;background-size:cover;}.mwjays1 .modal-journey > button{position:absolute;right:26px;top:32px;background:#FFFFFF;border-radius:48px;padding:8px 12px;}.mwjays1 .modal-journey > button span{font-weight:600;font-size:14px;line-height:16px;color:#000000;}
.m1vujb3n{max-width:480px;margin-top:210px;z-index:10;}.m1vujb3n h1{font-weight:600;font-size:40px;line-height:48px;color:#FFFFFF;text-transform:capitalize;margin-bottom:16px;max-width:350px;}.m1vujb3n > p{font-weight:600;font-size:18px;line-height:24px;color:#FFFFFF;margin-bottom:16px;}
.sbrdbe3{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-right:10px;margin-bottom:32px;}.sbrdbe3 > div{width:100%;height:4px;background:#1A1A1A;border-radius:5px;}.sbrdbe3 > div div{background:#FFFFFF;width:var(--sbrdbe3-2);height:100%;border-radius:5px;}@media only screen and (min-width:769px){.sbrdbe3{margin-right:0;}}
.c45g9qs > h2{font-weight:600;font-size:20px;line-height:26px;color:#FFFFFF;text-transform:capitalize;margin-top:36px;margin-bottom:24px;}
.t1tsjbmf{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;background:#1A1A1A;padding:20px 16px;border:1px solid #333333;border-radius:16px;margin-bottom:12px;cursor:var(--t1tsjbmf-2);min-height:106px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.i1yh5tt6{margin-left:16px;}.i1yh5tt6 h2{font-weight:600;font-size:16px;line-height:20px;text-transform:capitalize;color:#FFFFFF;margin-bottom:6px;}.i1yh5tt6 p{font-weight:500;font-size:14px;line-height:20px;color:#8E8E93;}
.oezoo2j{position:absolute;left:0;right:0;top:0;bottom:0;background:var(--oezoo2j-0);}
